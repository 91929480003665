var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-alert",
        { staticClass: "my-4", attrs: { type: "info", dense: "" } },
        [_vm._v(_vm._s(_vm.$t("views.reports.layout_info")))]
      ),
      _c(
        "ReportHeader",
        {
          attrs: {
            title: _vm.$t("views.reports.generator.title"),
            description: _vm.$t("views.reports.generator.description"),
            icon: "fas fa-user"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              },
              model: {
                value: _vm.isFormValid,
                callback: function($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("DateInput", {
                        attrs: {
                          label: _vm.$t(
                            "views.reports.commons.form.from.label"
                          ),
                          placeholder: _vm.$t(
                            "views.reports.commons.form.from.placeholder"
                          ),
                          hint: _vm.$t("views.reports.commons.form.from.hint"),
                          outputFormat: "YYYY-MM-DDTHH:mm:ssZ"
                        },
                        model: {
                          value: _vm.params.from,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "from", $$v)
                          },
                          expression: "params.from"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("DateInput", {
                        attrs: {
                          label: _vm.$t("views.reports.commons.form.to.label"),
                          placeholder: _vm.$t(
                            "views.reports.commons.form.to.placeholder"
                          ),
                          hint: _vm.$t("views.reports.commons.form.to.hint"),
                          outputFormat: "YYYY-MM-DDTHH:mm:ssZ"
                        },
                        model: {
                          value: _vm.params.to,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "to", $$v)
                          },
                          expression: "params.to"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("ReportGroupByCombobox", {
                        attrs: {
                          label: _vm.$t(
                            "views.reports.commons.form.group_by.label"
                          ),
                          hint: _vm.$t(
                            "views.reports.commons.form.group_by.hint"
                          ),
                          placeholder: _vm.$t(
                            "views.reports.commons.form.group_by.placeholder"
                          ),
                          availableItems: _vm.groupByAvailableItems,
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          "hide-selected": "",
                          "deletable-chips": "",
                          "persistent-hint": "",
                          max: 3
                        },
                        model: {
                          value: _vm.computedGroupBy,
                          callback: function($$v) {
                            _vm.computedGroupBy = $$v
                          },
                          expression: "computedGroupBy"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "views.reports.commons.form.count.label"
                          )
                        },
                        model: {
                          value: _vm.computedCount,
                          callback: function($$v) {
                            _vm.computedCount = $$v
                          },
                          expression: "computedCount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "views.reports.commons.form.include_reversals.label"
                          )
                        },
                        model: {
                          value: _vm.computedIncludeReversals,
                          callback: function($$v) {
                            _vm.computedIncludeReversals = $$v
                          },
                          expression: "computedIncludeReversals"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    type: "submit",
                    loading: _vm.loading,
                    disabled: !_vm.isFormValid
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ReportContent", {
        attrs: {
          headers: _vm.computedHeaders,
          reportResponse: _vm.reportResponse,
          loading: _vm.loading,
          pagination: _vm.pagination,
          hasError: _vm.hasError,
          count: _vm.computedLastCount,
          model: _vm.Generator
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          },
          export: _vm.exportReport
        },
        scopedSlots: _vm._u([
          {
            key: "group-item",
            fn: function(ref) {
              var item = ref.item
              var tdClass = ref.tdClass
              return [
                _c("td", { class: tdClass }, [
                  _vm._v(
                    _vm._s(_vm._f("currency")(item.operations["sum:value"]))
                  )
                ]),
                _c("td", { class: tdClass }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("dynamicPoint")(item.operations["sum:amount"])
                    )
                  )
                ]),
                _c("td", { class: tdClass }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("currency")(item.operations["sum:spent_value"])
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                !_vm.computedLastCount
                  ? _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("datetime")(item.created_at)))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("currency")(item.value)))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("dynamicPoint")(item.amount)))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("currency")(item.spent_value)))
                      ]),
                      _c("td", [_vm._v(_vm._s(item.subsidiary_name))])
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }